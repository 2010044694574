.single-post-content {

  ul, ol {
    padding: 0px;
    margin-left: 24px;
    list-style: none outside none;
    position: relative;
  }

  ul>li::before {
    display: inline-block;
    margin-top: 5px;
    content: " ";
    left: -23px;
    height: 12px;
    position: absolute;
    width: 12px;
    border-radius: 50%;
    background: none repeat scroll 0% 0% $colorUL;

  }

  #toc_container p.toc_title+ul.toc_list {
    margin-left: 24px;
  }

  #toc_container.no_bullets li,
  #toc_container.no_bullets ul,
  #toc_container.no_bullets ul li,
  .toc_widget_list.no_bullets,
  .toc_widget_list.no_bullets li {
    list-style: none;
  }


  #toc_container p.toc_title+ul.toc_list {
    margin-left: 23px;
    list-style: none;
    li:before {
      display: none;
    }
  }

  ol {
    list-style: decimal;
    margin-left: 24px;
    counter-reset: li;
    list-style: none outside none;
  }
  ol>li {
    position: relative;
    margin-left: 32px;
    margin-bottom: 16px;
  }

  ol>li::before {
    content: counter(li);
    counter-increment: li;
    height: 23px;
    left: -38px;
    position: absolute;
    text-align: center;
    width: 23px;
    color: #fff;
    border-radius: 50%;
    line-height: 23px;
    background: $colorOL;
    font-style: normal;
  }
}