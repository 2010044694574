header {
  height: 113px;
  padding: 8px 0;
  background: url('images/header-bg.png') left top repeat-x;
  margin-bottom: 5px;
  .logo {
    max-width: 100%;
  }
}

#header_menu  {
  vertical-align: middle;
  list-style: outside none none;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
  display: inline-block;

  li{
    display: inline;
    a {
      color: #444444;
      margin: 0 6px 0 0;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      border-radius: 8px;
      background: #F2F2F2 url('images/pagemenu-link.png') left repeat-x;
      border-left: 1px solid #D7D7D7;
      border-right: 1px solid #D7D7D7;
      border-bottom: 1px solid #D7D7D7;
      border-top: 1px solid #D7D7D7;
      padding: 7px 12px;
    }

    a:hover {
      color: #fff;
      background: #0D84DE url('images/pagemenu-hover.png') left top repeat-x;
      border-left: 1px solid #0E84DE;
      border-right: 1px solid #0E84DE;
      border-bottom: 1px solid #6AB1E7;
      border-top: 1px solid #0E84DE;
    }
  }

  .current-menu-item a {
    color: #fff;
    background: #0D84DE url('images/pagemenu-hover.png') left top repeat-x;
    border-left: 1px solid #0E84DE;
    border-right: 1px solid #0E84DE;
    border-bottom: 1px solid #6AB1E7;
    border-top: 1px solid #0E84DE;
  }

  .sub-menu { visibility: hidden;/**/ background: #333; }
  .sub-menu li { display: unset; }

}