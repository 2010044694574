
* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Verdana;
  font-size: 14px;
  color: #555555;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Condensed',sans-serif;
}


.bg-white {
  background-color: $body-bg;
}

.shadow{
  box-shadow: 0px 0px 20px #ddd;
}

img {
  max-width: 100%;
  height: auto;
}

table {
  width: 100%;
  margin-bottom: 20px;
  margin-bottom: 2rem;
  border: 1px solid #eaeaea;
  border-collapse: collapse;
  border-spacing: 0;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 1px solid #eaeaea;
  line-height: 1.42857;
  padding: 5px;
  padding: 0.5rem;
  vertical-align: middle;
  text-align: center;
}

table > thead > tr > th, table > thead > tr > td {
  border-bottom-width: 2px;
}

.left_side {

}

.right_side {

}

.border {
  border: 1px solid #D4D4D4;
}

.border-img {
  border: double 2px #D4D4D4;
  padding: 3px;
}

.ToTopBtn {
  cursor: pointer;
  display: block;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background: rgba(51,51,51,.58);
  padding: 1px 10px;
  font-size: 30px;
  color: #fff;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 1030
}

.no-radius {
  border-radius: 0px;
}

iframe {
  max-width: 100%;
}