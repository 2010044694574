.category_description {
   background-color: #FEFDF9;
   border: 1px solid #eeeeee;
}

.category-post-border {
   border: 1px solid #D4D4D4;
}

.read-more {
   a.btn {
      text-decoration: none;
      color: #4C4C4C;
   }
   a.btn:hover {
      color: #ffffff;
      background:#0979CF;
   }
}

.category-post-title {
   font-size: 26px;
   a {
      text-decoration: none;
      color: #0979CF;
   }
   a:hover {
      color: #49AFFE;
   }



}