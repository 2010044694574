.single-post {

}

.single-post-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: bold;
  color: #0979CF;
}

.single-post-content {

}