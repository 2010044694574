
.fixed-menu {
    position: fixed;
    top: 0px;
    right: -300px;
    width: 300px;
    z-index: 1032;
    transition: all 0.5s Linear;
    max-height: 100%;
    height: 100%;
    .header{
        height: $headerHeight;
    }

    .body {
        height: calc(100% - 40px) ;
        max-height: calc(100% - 40px);
        overflow-y: auto;
    }
}

.fixed-menu-open {
    right: 0px;
}
.right_side, .mobile_sidebar {
    .menu {
        list-style: none;
        text-align: left;
        padding: 0px;
        padding-bottom: 20px;

        li {
            margin: 0px;
            padding: 0px;
            background: #f9f9f9;
            border-bottom: 1px solid #eeeeee;

            a {
                color: #999999;
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 17px;
                display: block;
                padding: 9px 9px 9px 10px;
                text-decoration: none;

                text-overflow: ellipsis;

            }
            a:hover {
                background-color: #f4f4f4 !important;
            }

            /*a:before {
                content: '\0203A';
                color: #292b2c;
                padding-right: 10px;
            }*/

        }
        li:last-child {
            border-bottom: none;
        }

    }
}
