.bg-footer {
  background-color: #F9F9F9;
}

.bg-dark {
  background-color: #555555;
}

.bg-light-gray {
  background-color: #F6F6F6;
}

.bg-gray {
  background-color: #ccc;
}

