
.widget-title {
  font-size: 22px;
  text-transform: uppercase;
  font-family: Calibri, Arial, Helvetica, sans-serif;
  display: block;
  padding: 10px;
  margin: 10px 0px;
  color: #4E4D4D;
  border: 1px solid #E9E9E9;
  background: #F5F5F5;
  font-weight: bold;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-radius: .25rem;
}



.widget_nav_menu ul{
list-style: none;
padding: 5px;
}
.widget_nav_menu ul li {
margin-bottom: 1px;
/*border-bottom: solid 1px #AAA;*/
  color: #999999;
  background-color: #f4f4f4;
}

.widget_nav_menu ul li:nth-child(2n+1) {
    background: #F5F5F5 none repeat scroll 0% 0%;
}

.widget_nav_menu a {
padding: 5px;
display: block;
  transition: all 0.5s;
}

.widget_nav_menu a:hover{
  color: #CA0000;
  background: #DDD;
  text-decoration: none;
}


#recentcomments {
  list-style: none;
  padding: 0px 10px;
  li.recentcomments {
    padding: 5px 0px;
  }
}

.popular_posts {

  .popular_posts_container {

    .popular_posts_image {

    }

    .popular_posts_link {

    }
  }


  a:last-child{

  }
}


.widget_comment_text_block {
  border: 1px solid #D4D4D4;
  border-bottom: 5px solid #eeeeee;
  .comment_text {
    font-size: 14px;
    padding: 10px;

    a{
      color: #4E8359;
      text-decoration: underline;
    }
  }

  .widget_comment_text_post_link {
    border-top: #eeeeee 1px dashed;
  }

}
