footer {
  background: #F6F6F6;
  border: 1px solid #D4D4D4;
}

.copy {
  font-size: 15px;
  font-weight: bold;
  color: #0979CF;
}

.first-footer-block {
  padding: 10px 0;
  a {
    color: #ffffff;
    text-decoration: underline;
    margin: 5px;
  }
}

.footer-menu {
  a {
    padding: 5px;
    color: #666666;
    text-decoration: underline;
  }

  a:hover {
    color: #999999;
  }
}

.second-footer-block {

}