.mw-250{
  min-width: 250px;
}

.mw-200{
  min-width: 200px;
}

.preview {
  img {
    max-height: 200px;
  }
}

.post {
  margin-bottom: 20px;
  padding-top: 20px;
}

.post:hover {
  background-color: #f2f2f2;
}

.post-content:last-child {
  border-bottom: none;
}

.post-footer {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
}