.wp-caption-text {
  font-weight: 700;
  font-size: small;
}

ul.page-numbers { list-style: none;margin: 5px;padding: 0px;text-align: center;}
ul.page-numbers li {display: inline-block;}
a.page-numbers ,span.page-numbers { text-decoration:none;display: inline-block;padding: 12px;color: #fff;background: #4C4C4C;-webkit-transition: all 0.5;transition: all 0.5s;font-size: 15px;}
a.page-numbers:hover, li span.current, a.current {background: #A2A1A7; color: #fff;}

/*Похожие посты*/
.relate { text-align: center; }
.relate .img_thumb {
  width: 150px;
  height:150px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  float: none;

}
.img_thumb img { transition: all 0.2s linear 0s;}
.img_thumb img:hover { transform: scale(1.1); }

.relate_col {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  width:200px;
  vertical-align:top;
  -webkit-box-shadow:0px 0px 10px #8a8787, inset 0px 0px 1px #ffffff;
  -moz-box-shadow: 0px 0px 10px #8a8787,  inset 0px 0px 1px #ffffff;
  box-shadow:0px 0px 10px #8a8787, inset 0px 0px 1px #ffffff;
}

.relate_link { height: 22px; overflow: hidden; }

/*Навигиция в посте*/
.navSingle {text-align: center; margin: 10px;}
.navSingle a{
  border: 1px solid !important;
  padding: 8px;
  margin: 0px;
  font-size: 15px;
  display: inline-block;
  text-decoration: none;
}

/*Выравнивание*/
.aligncenter { display: block; margin-left: auto;margin-right: auto;}
.alignleft { float: left; margin: 0.5em 1em 0.5em 0px; }
.alignright { float: right;  margin: 0.5em 0px 0.5em 1em;}

.textwidget {background: #fff; text-align: left;}

footer .textwidget {background-color: none; padding: 0px;}


.errorsendform { display: none; padding: 10px; text-align: left; }
.errorsendform  label{ display: block; }
.errorsendform input, .errorsendform textarea { width: 100%; padding: 5px 5px; box-sizing: border-box;}


/*Информационніе блоки*/
.info, .warning, blockquote{
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid $blockquoteBorder;
  border-left-width: .25rem;
  border-radius: .25rem;
}

blockquote:before {
  content: '\0275D';
  font-size: 40px;
  color: $blockquoteBorder;
  position: absolute;
  top: 1.25rem;
  left: 10px;
}

blockquote {
  position: relative;
  padding-left: 50px;
}

.info {
  position: relative;
  padding-left: 50px;
  border-color: $infoBorder;
  .title {
    color: $infoBorder;
    font-size: 24px;
  }
}

.info:before {
  font-family: 'icomoon' !important;
  content: "\ea0c";
  font-size: 30px;
  color: $infoBorder;
  position: absolute;
  top: 1.25rem;
  left: 10px;
  padding: 0px;
  text-align: center;
}

.warning:before {
  content: "\026A0";
  font-size: 40px;
  color: $warningBorder;
  position: absolute;
  top: 1.25rem;
  left: 10px;
  padding: 0px;
  text-align: center;
}

.warning {
  border-color: $warningBorder;
  position: relative;
  padding-left: 50px;
  .title {
    color: $warningBorder;
    font-size: 24px;
  }
}

.fotorama {
  background-color: #333333;
  .fotorama__nav-wrap {
    border-top: 1px solid #00afea;
  }
}
