$body-bg:  #fff;
$headerHeight: 40px;
/*Цвет списков*/
$colorUL: #008cf5;
$colorOL: #c54e41;
/*Цвет Блоков*/
$blockquoteBorder: #d4d4d4;
$infoBorder: #008cf5;
$warningBorder: #c54e41;

@import "index";
@import "bg";
@import "lists";
@import "single-post";
@import "header";
@import "category";
@import "menu";
@import "widgets";
@import "main-page-posts";
@import "comments";
@import "wpUtilities";
@import "footer";
